<template>
    <div>
      <table>
        <thead>
          <tr>
            <th>Tracking No</th>
            <th>Manifest No</th>
            <th>Status</th>
            <th>Weight</th>
            <th>Collected</th>
            <th>Branch</th>
            <th>Customer Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pkg in packages" :key="pkg.id">
            <td>{{ pkg.trackingNo }}</td>
            <td>{{ pkg.manifestNumber }}</td>
            <td>{{ pkg.status }}</td>
            <td>{{ pkg.originalWeight }}</td>
            <td>{{ pkg.collected }}</td>
            <td>{{ pkg.Branch ? pkg.Branch.name : 'N/A' }}</td>
            <td>{{ pkg.Customer ? `${pkg.Customer.firstName} ${pkg.Customer.lastName}` : 'N/A' }}</td>
            <td>{{ pkg.description }}</td>
            <td>
              <button class="view-button" @click="$emit('view-package', pkg)">View</button>
              <button class="edit-button" @click="$emit('edit-package', pkg)">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PackageTable',
    props: {
      packages: {
        type: Array,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: var(--primary-color);
    color: white;
    text-align: left;
  }
  
  td {
    background-color: white;
  }
  
  button {
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 5px 0;
  }
  
  .view-button {
    background-color: var(--primary-color);
  }
  
  .view-button:hover {
    background-color: #3498db;
  }
  
  .edit-button {
    background-color: #ffc107;
  }
  
  .edit-button:hover {
    background-color: #ffca2c;
  }
  </style>
  