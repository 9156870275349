<template>
  <div class="shipment-details-page">
    <h1>Shipment Details</h1>

    <!-- Search Bar -->
    <div class="search-bar">
      <input v-model="searchQuery" type="text" placeholder="Search by Shipment ID or Date Range..." @input="searchShipment" />
      <button @click="clearSearch" class="clear-button">Clear</button>
    </div>

    <!-- Shipment Select Dropdown -->
    <div class="shipment-select">
      <label for="shipment-select">Select Shipment:</label>
      <select id="shipment-select" v-model="selectedShipmentId" @change="fetchPackagesByShipmentId">
        <option v-for="shipment in shipments" :key="shipment.id" :value="shipment.id">
          {{ shipment.id }} - {{ formatDate(shipment.createdAt) }}
        </option>
      </select>
    </div>

    <!-- Package Table -->
    <PackageTable :packages="packages" @view-package="viewPackage" @edit-package="editPackage" />

    <!-- View Modal -->
    <div v-if="selectedPackage" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>Package Details</h2>
        <p><strong>Tracking No:</strong> {{ selectedPackage.trackingNo }}</p>
        <p><strong>Manifest No:</strong> {{ selectedPackage.manifestNumber }}</p>
        <p><strong>Description:</strong> {{ selectedPackage.description }}</p>
        <p><strong>Weight:</strong> {{ selectedPackage.originalWeight }}</p>
        <p><strong>Status:</strong> {{ selectedPackage.status }}</p>
        <p><strong>Collected:</strong> {{ selectedPackage.collected }}</p>
        <p><strong>Branch:</strong> {{ selectedPackage.branchId ? getBranchName(selectedPackage.branchId) : 'N/A' }}</p>
        <p><strong>Customer Name:</strong> {{ selectedPackage.Customer ? `${selectedPackage.Customer.firstName} ${selectedPackage.Customer.lastName}` : 'N/A' }}</p>
        <p><strong>Customs Fee:</strong> {{ selectedPackage.customsFee }}</p>
        <p><strong>Delivery Fee:</strong> {{ selectedPackage.deliveryFee }}</p>
        <p><strong>Merchant:</strong> {{ selectedPackage.merchant }}</p>
        <p><strong>Created At:</strong> {{ formatDate(selectedPackage.createdAt) }}</p>
        <p><strong>Updated At:</strong> {{ formatDate(selectedPackage.updatedAt) }}</p>
      </div>
    </div>

      <!-- Edit Package Modal -->
    <EditPackageModal
      v-if="editingPackage"
      :showModal="isModalVisible"
      :package-to-edit="editingPackage"
      @save="updatePackage"
      @close="closeEditModal"
      @open-customer-search="openCustomerSearch"
    />

    <!-- Customer Search Modal -->
    <div v-if="showCustomerSearch" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeCustomerSearch">&times;</span>
        <h2>Search Customer</h2>
        <input v-model="customerSearchQuery" @input="searchCustomer" type="text" placeholder="Search by name..." />
        <ul>
          <li v-for="customer in customerSearchResults" :key="customer.id" @click="selectCustomer(customer)">
            {{ customer.firstName }} {{ customer.lastName }} ({{ customer.mailboxNumber }}) - {{ getBranchName(customer.branchId) }}
          </li>
        </ul>
        <p v-if="customerSearchResults.length === 0">No customers found</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PackageTable from './PackageTable.vue';
import EditPackageModal from './common/EditPackageModal.vue';

export default {
  name: 'ShipmentDetails',
  components: { PackageTable, EditPackageModal },
  data() {
    return {
      searchQuery: '',
      selectedShipmentId: null,
      selectedPackage: null,
      editingPackage: null,
      isModalVisible: false,
      showCustomerSearch: false,
      customerSearchQuery: '',
    };
  },
  computed: {
    ...mapState({
      shipments: state => state.shipments,
      packages: state => state.packages || [],
      customerSearchResults: state => state.customerSearchResults || []
    })
  },
  methods: {
    ...mapActions(['fetchAllShipments', 'fetchPackagesByShipmentId', 'searchCustomer', 'updatePackage']),
    viewPackage(pkg) {
      this.selectedPackage = pkg;
    },
    editPackage(pkg) {
      this.editingPackage = pkg;
      this.isModalVisible = true; // Open the modal
    },
    closeModal() {
      this.selectedPackage = null;
    },
    closeEditModal() {
      this.isModalVisible = false; // Close the modal
      this.editingPackage = null;
    },
    async fetchPackagesByShipmentId() {
      if (this.selectedShipmentId) {
        await this.$store.dispatch('fetchPackagesByShipmentId', this.selectedShipmentId);
      }
    },
    async searchShipment() {
      await this.fetchAllShipments();
    },
    async updatePackage(pkg) {
      try {
        const packageUpdate = { ...pkg };
        packageUpdate.ownerId = packageUpdate.Customer ? packageUpdate.Customer.id : null;
        await this.$store.dispatch('updatePackage', packageUpdate);
        this.closeEditModal();
        this.fetchPackagesByShipmentId();
      } catch (error) {
        console.error('Error updating package:', error);
      }
    },
    formatDate(dateString) {
      const options = { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    clearSearch() {
      this.searchQuery = '';
      this.selectedShipmentId = null;
      this.packages = [];
    },
    openCustomerSearch() {
      this.showCustomerSearch = true;
    },
    closeCustomerSearch() {
      this.showCustomerSearch = false;
    },
    async searchCustomer() {
      await this.$store.dispatch('searchCustomer', this.customerSearchQuery);
    },
    selectCustomer(customer) {
      this.editingPackage.Customer = customer;
      this.editingPackage.branchId = customer.branchId;
      this.showCustomerSearch = false;
    },
    getBranchName(branchId) {
      const branchMap = {
        7: "Santa Cruz - Primary Location",
        15: "Portmore",
        16: "Old Harbour",
        17: "New Kingston"
      };
      return branchMap[branchId] || "N/A";
    }
  },
  async mounted() {
    await this.fetchAllShipments();
  }
};
</script>

<style scoped>
.shipment-details-page {
  padding: 20px;
  margin: 0 auto;
}

.search-bar {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.search-bar input {
  width: 80%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.clear-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-button:hover {
  background-color: #cc0000;
}

.shipment-select {
  margin-bottom: 20px;
}

.shipment-select select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 600px;
  position: relative;
  max-height: 90%;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  margin-right: 10px;
  font-weight: bold;
  width: 150px;
}

input, select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex: 1;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-actions button {
  margin-left: 0;
  flex: 1;
  margin-right: 10px;
}

.edit-button {
  background-color: #ffc107;
}

.edit-button:hover {
  background-color: #ffca2c;
}

.view-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #0056b3;
}
</style>
