<template>
  <div class="recent-packages-page">
    <h1>Packages Requiring Attention</h1>
    <div class="search-bar">
      <input v-model="searchQuery" type="text" placeholder="Search by Manifest or Tracking Number..." @input="searchPackage" />
    </div>   

    <PackageTable :packages="displayedPackages" @view-package="viewPackage" @edit-package="editPackage" />

    <!-- Page Size Selection -->
    <div class="page-size-selection">
      <label for="pageSize">Page Size:</label>
      <select v-model="pagination.pageSize" @change="updatePageSize">
        <option :value="10">10</option>
        <option :value="20">20</option>
        <option :value="50">50</option>
        <option :value="100">100</option>
      </select>
    </div>   

    <div class="pagination top-pagination">
      <button @click="prevPage" :disabled="pagination.page === 1">Previous</button>
      <span>Page {{ pagination.page }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="pagination.page === totalPages">Next</button>
    </div>

    <!-- View Modal -->
    <div v-if="selectedPackage" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>Package Details</h2>
        <p><strong>Tracking No:</strong> {{ selectedPackage.trackingNo }}</p>
        <p><strong>Manifest No:</strong> {{ selectedPackage.manifestNumber }}</p>
        <p><strong>Description:</strong> {{ selectedPackage.description }}</p>
        <p><strong>Weight:</strong> {{ selectedPackage.originalWeight }}</p>
        <p><strong>Status:</strong> {{ selectedPackage.status }}</p>
        <p><strong>Collected:</strong> {{ selectedPackage.collected }}</p>
        <p><strong>Branch:</strong> {{ selectedPackage.branchId ? getBranchName(selectedPackage.branchId) : 'N/A' }}</p>
        <p><strong>Customer Name:</strong> {{ selectedPackage.Customer ? `${selectedPackage.Customer.firstName} ${selectedPackage.Customer.lastName}` : 'N/A' }}</p>
        <p><strong>Customs Fee:</strong> {{ selectedPackage.customsFee }}</p>
        <p><strong>Delivery Fee:</strong> {{ selectedPackage.deliveryFee }}</p>
        <p><strong>Merchant:</strong> {{ selectedPackage.merchant }}</p>
        <p><strong>Created At:</strong> {{ formatDate(selectedPackage.createdAt) }}</p>
        <p><strong>Updated At:</strong> {{ formatDate(selectedPackage.updatedAt) }}</p>
      </div>
    </div>

    <!-- Edit Modal -->
    <div v-if="editingPackage" class="modal">
      <div class="modal-content">
        <h2>Edit Package</h2>
        <form @submit.prevent="updatePackage">
          <div class="form-group">
            <label for="trackingNo">Tracking No:</label>
            <input id="trackingNo" v-model="editingPackage.trackingNo" type="text" />
          </div>
          <div class="form-group">
            <label for="manifestNumber">Manifest No:</label>
            <input id="manifestNumber" v-model="editingPackage.manifestNumber" type="text" />
          </div>
          <div class="form-group">
            <label for="description">Description:</label>
            <input id="description" v-model="editingPackage.description" type="text" />
          </div>
          <div class="form-group">
            <label for="originalWeight">Weight:</label>
            <input id="originalWeight" v-model="editingPackage.originalWeight" type="float" />
          </div>
          <div class="form-group">
            <label for="status">Status:</label>
            <select id="status" v-model="editingPackage.status">
              <option value="Pre Alert">Pre Alert</option>
              <option value="At Warehouse">At Warehouse</option>
              <option value="Collected">Collected</option>
              <option value="In Transit">In Transit</option>
              <option value="Ready">Ready</option>
              <option value="Delivered">Delivered</option>
              <option value="Out For Delivery">Out For Delivery</option>
              <option value="Entered">Entered</option>
              <option value="Attention">Attention</option>
            </select>
          </div>
          <div class="form-group">
            <label for="collected">Collected:</label>
            <select id="collected" v-model="editingPackage.collected">
              <option value="no">No</option>
              <option value="yes">Yes</option>
              <option value="partial">Partial</option>
            </select>
          </div>
          <div class="form-group">
            <label for="branch">Branch:</label>
            <select id="branch" v-model="editingPackage.branchId">
              <option :value="7">Santa Cruz - Primary Location</option>
              <option :value="15">Portmore</option>
              <option :value="16">Old Harbour</option>
              <option :value="17">New Kingston</option>
            </select>
          </div>
          <div class="form-group">
            <label for="customerName">Customer Name:</label>
            <input id="customerName" v-model="customerName" type="text" disabled />
            <button type="button" @click="openCustomerSearch" class="lookup-button">Lookup</button>
          </div>
          <div class="form-group">
            <label for="mailboxNumber">Mailbox Number:</label>
            <input id="mailboxNumber" :value="editingPackage.Customer?.mailboxNumber || ''" type="text" readonly />
          </div>
          <div class="form-group">
            <label for="customsFee">Customs Fee:</label>
            <input id="customsFee" v-model="editingPackage.customsFee" type="float" />
          </div>
          <div class="form-group">
            <label for="deliveryFee">Delivery Fee:</label>
            <input id="deliveryFee" v-model="editingPackage.deliveryFee" type="float" />
          </div>
          <div class="form-group">
            <label for="merchant">Merchant:</label>
            <input id="merchant" v-model="editingPackage.merchant" type="text" />
          </div>
          <div class="form-group">
            <label for="createdAt">Created At:</label>
            <input id="createdAt" v-model="editingPackage.createdAt" type="text" disabled />
          </div>
          <div class="form-group">
            <label for="updatedAt">Updated At:</label>
            <input id="updatedAt" v-model="editingPackage.updatedAt" type="text" disabled />
          </div>
          <div class="form-actions">
            <button type="submit" class="save-button">Save</button>
            <button type="button" @click="closeEditModal" class="cancel-button">Cancel</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Customer Search Modal -->
    <div v-if="showCustomerSearch" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeCustomerSearch">&times;</span>
        <h2>Search Customer</h2>
        <input v-model="customerSearchQuery" @input="searchCustomer" type="text" placeholder="Search by name..." />
        <ul>
          <li v-for="customer in customerSearchResults" :key="customer.id" @click="selectCustomer(customer)">
            {{ customer.firstName }} {{ customer.lastName }} ({{ customer.mailboxNumber }}) - {{ getBranchName(customer.branchId) }}
          </li>
        </ul>
        <p v-if="customerSearchResults.length === 0">No customers found</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PackageTable from './PackageTable.vue';

export default {
  name: 'RecentPackages',
  components: { PackageTable },
  data() {
    return {
      selectedPackage: null,
      editingPackage: null,
      searchQuery: '',
      showCustomerSearch: false,
      customerSearchQuery: '',
      customerName: '',
      branchId: ''
    };
  },
  computed: {
    ...mapState({
      recentShipments: state => state.recentShipments,
      pagination: state => state.pagination,
      customerSearchResults: state => state.customerSearchResults || []
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.pageSize);
    },
    displayedPackages() {
      return this.recentShipments; // Already sliced by the backend, no need to slice further
    }
  },
  methods: {
    ...mapActions(['fetchPackagesRequiringAttention', 'searchPackages', 'updatePackage', 'searchCustomer']),
    viewPackage(pkg) {
      this.selectedPackage = pkg;
    },
    editPackage(pkg) {
      this.editingPackage = { 
        ...pkg, 
        Customer: pkg.Customer ? { ...pkg.Customer } : {}, 
        Branch: pkg.Branch ? { ...pkg.Branch } : {} 
      };
      this.customerName = this.editingPackage.Customer ? `${this.editingPackage.Customer.firstName} ${this.editingPackage.Customer.lastName} (${this.editingPackage.Customer.mailboxNumber})` : '';
      this.branchId = this.editingPackage.Customer ? `${this.editingPackage.Customer.branchId}` : '';
    },
    closeModal() {
      this.selectedPackage = null;
    },
    closeEditModal() {
      this.editingPackage = null;
      this.customerName = '';
      this.branchId = ''
    },
    prevPage() {
      if (this.pagination.page > 1) {
        this.pagination.page--;
        this.fetchPackagesRequiringAttention({ page: this.pagination.page, limit: this.pagination.pageSize });
      }
    },
    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pagination.page++;
        this.fetchPackagesRequiringAttention({ page: this.pagination.page, pageSize: this.pagination.pageSize });
      }
    },
    async searchPackage() {
      if (this.searchQuery) {
        await this.searchPackages(this.searchQuery);
      } else {
        this.fetchPackagesRequiringAttention({ page: this.pagination.page, pageSize: this.pagination.pageSize });
      }
    },
    async updatePackage() {
      try {
        const packageUpdate = { ...this.editingPackage };
        packageUpdate.branchId = packageUpdate.Branch ? packageUpdate.Branch.id : null;
        packageUpdate.ownerId = packageUpdate.Customer ? packageUpdate.Customer.id : null;
        await this.$store.dispatch('updatePackage', packageUpdate);
        this.closeEditModal();
        this.fetchPackagesRequiringAttention({ page: this.pagination.page, pageSize: this.pagination.pageSize });
      } catch (error) {
        console.error('Error updating package:', error);
      }
    },
    updatePageSize() {
      this.fetchPackagesRequiringAttention({ page: this.pagination.page, pageSize: this.pagination.pageSize });
    },
    formatDate(dateString) {
      const options = { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    openCustomerSearch() {
      this.showCustomerSearch = true;
    },
    closeCustomerSearch() {
      this.showCustomerSearch = false;
    },
    async searchCustomer() {
      await this.$store.dispatch('searchCustomer', this.customerSearchQuery);
    },
    selectCustomer(customer) {
      this.editingPackage.Customer = customer;
      this.editingPackage.branchId = customer.branchId;
      this.customerName = `${customer.firstName} ${customer.lastName} (${customer.mailboxNumber})`;
      this.showCustomerSearch = false;
    },
    getBranchName(branchId) {
      const branchMap = {
        7: "Santa Cruz - Primary Location",
        15: "Portmore",
        16: "Old Harbour",
        17: "New Kingston"
      };
      return branchMap[branchId] || "N/A";
    }
  },
  async mounted() {
    await this.fetchPackagesRequiringAttention({ page: this.pagination.page, pageSize: this.pagination.pageSize });
  }
};
</script>

<style scoped>
/* Add your scoped styles here */

.page-size-selection {
  margin: 10px 0;
}

.page-size-selection select {
  padding: 5px;
  font-size: 16px;
  margin-left: 10px;
}

.recent-packages-page {
  padding: 20px;
}


.search-filter-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-bar input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}


.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.pagination button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
  font-weight: bold;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 600px;
  position: relative;
  max-height: 90%;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  margin-right: 10px;
  font-weight: bold;
  width: 150px;
}

input, select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-actions button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.form-actions .cancel-button {
  background-color: #ccc;
}

.form-actions .save-button:hover {
  background-color: var(--hover-color);
}

.lookup-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.lookup-button:hover {
  background-color: var(--hover-color);
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

ul li:hover {
  background-color: #f0f0f0;
}
</style>
