<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="$emit('close')">&times;</span>
      <h2>Edit Package</h2>
      <form @submit.prevent="save">
        <div class="form-group">
          <label for="trackingNo">Tracking No:</label>
          <input id="trackingNo" v-model="localPackage.trackingNo" type="text" />
        </div>
        <div class="form-group">
          <label for="manifestNumber">Manifest No:</label>
          <input id="manifestNumber" v-model="localPackage.manifestNumber" type="text" />
        </div>
        <div class="form-group">
          <label for="description">Description:</label>
          <input id="description" v-model="localPackage.description" type="text" />
        </div>
        <div class="form-group">
          <label for="originalWeight">Weight:</label>
          <input id="originalWeight" v-model="localPackage.originalWeight" type="float" />
        </div>
        <div class="form-group">
          <label for="status">Status:</label>
          <select id="status" v-model="localPackage.status">
            <option value="Pre Alert">Pre Alert</option>
            <option value="At Warehouse">At Warehouse</option>
            <option value="Collected">Collected</option>
            <option value="In Transit">In Transit</option>
            <option value="Ready">Ready</option>
            <option value="Delivered">Delivered</option>
            <option value="Out For Delivery">Out For Delivery</option>
            <option value="Entered">Entered</option>
            <option value="Attention">Attention</option>
          </select>
        </div>
        <div class="form-group">
          <label for="collected">Collected:</label>
          <select id="collected" v-model="localPackage.collected">
            <option value="no">No</option>
            <option value="yes">Yes</option>
            <option value="partial">Partial</option>
          </select>
        </div>
        <div class="form-group">
          <label for="branch">Branch:</label>
          <select id="branch" v-model="localPackage.branchId">
            <option :value="7">Santa Cruz - Primary Location</option>
            <option :value="15">Portmore</option>
            <option :value="16">Old Harbour</option>
            <option :value="17">New Kingston</option>
          </select>
        </div>
        <div class="form-group">
          <label for="customerName">Customer Name:</label>
          <input id="customerName" v-model="customerName" type="text" disabled />
          <button type="button" @click="openCustomerSearch" class="lookup-button">Lookup</button>
        </div>
        <div class="form-group">
          <label for="mailboxNumber">Mailbox Number:</label>
          <input id="mailboxNumber" :value="localPackage.Customer?.mailboxNumber || ''" type="text" readonly />
        </div>
        <div class="form-group">
          <label for="customsFee">Customs Fee:</label>
          <input id="customsFee" v-model="localPackage.customsFee" type="number" />
        </div>
        <div class="form-group">
          <label for="deliveryFee">Delivery Fee:</label>
          <input id="deliveryFee" v-model="localPackage.deliveryFee" type="number" />
        </div>
        <div class="form-group">
          <label for="merchant">Merchant:</label>
          <input id="merchant" v-model="localPackage.merchant" type="text" />
        </div>
        <div class="form-group">
          <label for="createdAt">Created At:</label>
          <input id="createdAt" v-model="localPackage.createdAt" type="text" disabled />
        </div>
        <div class="form-group">
          <label for="updatedAt">Updated At:</label>
          <input id="updatedAt" v-model="localPackage.updatedAt" type="text" disabled />
        </div>
        <div class="form-actions">
          <button type="submit" class="save-button">Save</button>
          <button type="button" @click="$emit('close')" class="cancel-button">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditPackageModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    packageToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localPackage: { ...this.packageToEdit }, // Create a local copy of the package to avoid mutating the prop directly
      customerName: this.packageToEdit.Customer ? `${this.packageToEdit.Customer.firstName} ${this.packageToEdit.Customer.lastName}` : '',
    };
  },
  methods: {
    save() {
      this.$emit('save', this.localPackage);
    },
    openCustomerSearch() {
      this.$emit('open-customer-search');
    },
  },
  watch: {
    packageToEdit(newPackage) {
      // Update localPackage when packageToEdit prop changes
      this.localPackage = { ...newPackage };
      this.customerName = newPackage.Customer ? `${newPackage.Customer.firstName} ${newPackage.Customer.lastName}` : '';
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 600px;
  position: relative;
  max-height: 90%;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  margin-right: 10px;
  font-weight: bold;
  width: 150px;
}

input,
select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-actions button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.form-actions .cancel-button {
  background-color: #ccc;
}

.form-actions .save-button:hover {
  background-color: var(--hover-color);
}

.lookup-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.lookup-button:hover {
  background-color: var(--hover-color);
}
</style>
